import React from 'react'
import LicensePricingCards from './LicensePricingCards.js'

const GetYourLicensePricing = ({products, outerCss}) => {
  return (
    <div>
      <LicensePricingCards
        products={products}
        outerCss={outerCss}
      />
    </div>
  )
}

export default GetYourLicensePricing