import React, { useState, useRef } from 'react'
import { ContactForm } from '../pages/contact/get-your-license-contact'
import { styles as buttonStyles} from './Button'

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2.5rem',
  },
  button: {
    cursor: 'pointer',
  }
}

const LearnMore = ({title}) => {
  const [showForm, setShowForm] = useState(false)
  let contactFormRef = useRef(null)
  const onClick = () => {
    setShowForm(!showForm)
    if(!showForm) {
      return contactFormRef.current.scrollIntoView({behavior: "smooth", block: "center"})
    }
  }
  return (
    <div>
      <div css={styles.buttonContainer}>
        <button
          css={[buttonStyles.secondary, buttonStyles.largeMixin, styles.button]}
          onClick={onClick}
        >
          {title}
        </button>
      </div>
      <div ref={contactFormRef}>
        {showForm && <ContactForm/>}
      </div>
    </div>
  )
}

export default LearnMore
