import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import { colors, presets, themeStyles } from '../utils/theme'

import GetYourLicensePricing from '../components/GetYourLicensePricing'
import LearnMore from '../components/LearnMore'
import Button from '../components/Button'
import { rhythm } from '../utils/typography'

const styles = {
  title: {
    color: colors.darkGreen,
    textAlign: 'center',
    fontSize: rhythm(1.5),
    marginTop: rhythm(2),
  },
  buttonContainer: {
    marginTop: rhythm(1),
    display: 'none',
    [presets.Tablet]: {
      display: 'grid',
    }
  }
}

export const GetYourLicensePageOptions = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const {
    showHeaderLinks,
    title,
    metadataTitle,
    metadataDescription,
    products,
    contactFormHeader,
  } = frontmatter

  return (
    <Layout showLinks={showHeaderLinks} metadataTitle={metadataTitle} metadataDescription={metadataDescription}>
      <div css={[themeStyles.contentWidth, styles.buttonContainer]}>
        <Button
          text='Back'
          to='/get-your-license/'
        />
      </div>
      <div>
        <h2 style={styles.title}>{title}</h2>
        <div>
          <GetYourLicensePricing
            outerCss={themeStyles.contentWidth}
            products={products}
          />
        </div>
      </div>
      <LearnMore
        title={contactFormHeader}
      />
    </Layout>
  )
}

GetYourLicensePageOptions.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        showHeaderLinks: PropTypes.bool,
        title: PropTypes.string,
        metadataTitle: PropTypes.string,
        metadataDescription: PropTypes.string,
        products: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            stripePaymentUrl: PropTypes.string,
            price: PropTypes.number,
            includedOptions: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string,
                subTexts: PropTypes.arrayOf(
                  PropTypes.shape({
                    value: PropTypes.string,
                  })
                )
              })
            ),
          }),
        ),
        contactFormHeader: PropTypes.string,
      })
    })
  })
}

export default GetYourLicensePageOptions

export const pageQuery = graphql`
  query GetYourLicensePageOptionsQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "get-your-license-options"} }) {
      frontmatter {
        showHeaderLinks
        metadataTitle
        metadataDescription
        title
        description
        products {
          title
          price
          stripePaymentUrl
          includedOptions {
            text
            subTexts{
              value
            }
          }
        }
        contactFormHeader
      }
    }
  }
`