import React from 'react'
import { colors, presets } from '../utils/theme'
import { rhythm } from '../utils/typography'
import Checkmark from '../img/icons/checkmark-green.svg'
import _ from 'lodash'
import Button from './Button'

const styles = {
  cardsContainer: {
    backgroundColor: colors.blueGreen,
    borderRadius: '20px',
    width: '85%',
    margin: 'auto',
    marginTop: rhythm(1),
    padding: `${rhythm(0.1)} ${rhythm(0.1)} ${rhythm(1)}`,
    [presets.Tablet]: {
      flexDirection: 'column',
      width: '60%',
    },
    [presets.Desktop]: {
      width: '100%',
      padding: `${rhythm(0.3)} ${rhythm(0.3)} ${rhythm(1)}`,
      display: 'flex',
      flexDirection: 'row',
    },
  },
  cardItem: {
    border: '1px solid',
    borderRadius: '20px',
    padding: rhythm(1),
    justifySelf: 'stretch',
    backgroundColor: 'white',
    margin: '15px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardRowItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  cardHeader: {
    fontWeight: 600,
    fontSize: '1.8em',
  },
  cardTitle: {
    color: colors.darkGreen,
    borderBottom: `1px solid ${colors.blueGreen}`,
    paddingBottom: '0.5rem',
    margin: 0,
  },
  cardPrice: {
    color: colors.green,
    paddingTop: '0.5rem',
  },
  benefit: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: rhythm(1.125),
    height: rhythm(1.125),
    margin: 0,
  },
  text: {
    marginLeft: '8px',
    marginBottom: 0,
    lineHeight: rhythm(1.125),
    fontSize: rhythm(0.8),
    fontWeight: 400,
    color: colors.darkGreen,
  },
  bullet: {
    'paddingLeft': '0.8em',
  },
  buttonContainer: {
    margin: 'auto auto 0 auto',
    display: 'flex',
    flexDirection: 'row',
  },
}

const BenefitSubText = ({ subtext }) => {
  return <li css={styles.text}>{subtext.value}</li>
}

const LicensePricingBenefit = ({ benefit }) => {
  const subTexts = benefit.subTexts ?_.map(benefit.subTexts, (subText, index) => {
    return <BenefitSubText subtext={subText} key={index}/>
  }) : null
  return (
    <div css={styles.benefit}>
      <img css={styles.icon} src={Checkmark} alt='Checkmark' />
      <div>
        <dt css={styles.text}>{benefit.text}</dt>
        <ul css={styles.bullet}>
          {subTexts}
        </ul>
      </div>
    </div>
  )
}

const LicensePricingCard = ({product, style}) => {

  return (
    <dl css={[styles.cardItem, style]}>
      <div css={styles.cardRowItem}>
        <h2 css={[styles.cardHeader, styles.cardTitle]}>{product.title}</h2>
        <h2 css={[styles.cardHeader, styles.cardPrice]}>{`$${product.price}`}<span css={{fontWeight: 400}}> {product.priceComment}</span></h2>
        <div>
          {_.map(product.includedOptions, (option, index) => (
            <LicensePricingBenefit benefit={option} key={index} />))}
        </div>
      </div>
      <div css={styles.buttonContainer}>
        <Button
          text='Continue'
          href={product.stripePaymentUrl}
          analyticsTargetName={`Get Your License - Redirect to ${product.title} Stripe payment link`}
        />
      </div>
    </dl>
  )
}

const LicensePricingCards = ({ products, outerCss }) => {
  const productsCards = _.map(products, (product, index) => {
    return (<LicensePricingCard
      key={index}
      product={product}
      />)
    })
    return (
      <div css={[outerCss]}>
        <div css={styles.cardsContainer}>
          {productsCards}
        </div>
      </div>
  )
}

export default LicensePricingCards